<template>
    <JobLoading
        v-if="(job && isLoading) || isDeletingJob || isCreatingJob"
        :title="!isCreatingJob ? 'Edit Job' : ''"
        :message="loadingMessage"
        :subtext="'You are almost on your way'"
        :progress="jobLoadingProgress"
    />
    <div
        v-if="!job || !isLoading"
        :class="`job-form${!job ? ' job-create-form':''}`"
    >
        <div 
            class="job-form__loading-message"
            v-if="isLoading">
            <Icon 
                class="job-form__loading-spinner"
                type="loading2"></Icon>
            Some fields are disabled while exam content loads
        </div>
        <div v-if="job && $route.params.jobId" class="job-form__progress">
            <TitleText 
                class="job-form__title-text"
                :class="{
                    'job-form__title-text--edit-job': job && $route.params.jobId
                }"
            >
                Edit Job
            </TitleText>
            <JobInfo :job="job" class="job-form__progress-bar" />
        </div>
        <TitleText v-else class="job-form__title-text">
            Create Job
        </TitleText>
        <div
            class="job-form__form"
        >
            <div v-if="job" class="job-form__existing-job">
                <div class="job-form__existing-job-name"> {{  job.name }} </div>
                <div clas="=job-form__existing-job-subtext"> 
                    {{ job.type }} 
                    {{  job.type ? '|' : '' }}
                    {{ job.totalNewQuestionTemplates || job.totalReviewQuestions }} 
                    Questions
                    {{ job.totalReviewQuestions 
                        ? 'in' 
                        : 'for' }} 
                    {{ targetExam?.label }}
                </div>
            </div>
            <div 
                v-else
                class="job-form__job-type">
                <div class="job-form__section-title">
                    <Highlight />
                    Job Type
                </div>
                <PocketSelect
                    class="job-form__field"
                    v-model="defaultExam"
                    label="Exam"
                    :disabled="isLoading"
                    :data="formattedExamList"
                    :typeahead="!isLoading"
                    @update:modelValue="examSelected"
                >
                    <template #dropdownListItem="{ item }">
                        <div class="job-form__exam-option">
                            <div class="job-form__exam-option-label">
                                {{ item.label }}
                            </div>
                            <div class="job-form__exam-option-composite-key">
                                {{ item.compositeKey }}
                            </div>
                        </div>
                    </template>
                </PocketSelect>
                <PocketSelect
                    class="job-form__field"
                    v-model="selectedJobType"
                    label="Job Type"
                    :data="jobTypes"
                    :disabled="isLoading"
                />
                <PocketSelect
                    class="job-form__field"
                    v-model="targetExam"
                    label="Question Bank"
                    :data="targetExams"
                    :disabled="!selectedExam || isLoading"
                />
            </div>
            <div class="job-form__section-title">
                <Highlight />
                Job Details
            </div>
            <div class="job-form__job-details">
                <div class="job-form__left-col-job-details">
                    <Calendar
                        class="job-form__field"
                        v-model="dueDate"
                        label="Job Due Date"
                        v-if="!job || !isLoading"
                    />
                    <PocketSelect
                        class="job-form__writer-select"
                        v-model="writer"
                        label="Writer"
                        typeahead="true"
                        :data="formattedUserList('Writer')"
                    />
                    <PocketSelect
                        class="job-form__editor-select"
                        v-model="editor"
                        label="Editor"
                        typeahead="true"
                        :data="formattedUserList('Editor')"
                    />
                    <PocketSelect
                        class="job-form__lead-select"
                        v-model="lead"
                        label="Lead"
                        typeahead="true"
                        :data="formattedUserList('Admin')"
                    />
                </div>
                <div class="job-form__right-col-job-details">
                    <div class="job-form__field">
                        <PocketSelect
                            class="job-form__keyword-select"
                            v-model="keywordStyling"
                            label="Keyword Styling"
                            :data="keywordStyles"
                        >
                            <template v-if="keywordStyling" #selectValue="{ item }">
                                <div
                                    class="job-form__keyword-value"
                                    :class="{
                                        'job-form__keyword-value--bold': [
                                            'uppercaseBold',
                                            'bold'
                                        ].includes(item.value)
                                    }"
                                >{{ item.label }}</div>
                            </template>
                            <template #dropdownListItem="{ item }">
                                <div
                                    class="job-form__keyword-option"
                                    :class="{
                                        'job-form__keyword-option--bold': [
                                            'uppercaseBold',
                                            'bold'
                                        ].includes(item.value)
                                    }"
                                >{{ item.label }}</div>
                            </template>
                        </PocketSelect>
                    </div>
                    <div class="job-form__field">
                        <div class="job-form__editor-field-label">
                            Notes & Links
                        </div>
                        <EditorField
                            class="job-form__notes-and-links-editor"
                            v-model="notesAndLinks"
                            :enable-links="true"
                        />
                        <div 
                            class="job-form__notes-and-links-placeholder"
                            v-if="!isNotesEdited">
                            Add any important notes for writer and editor here
                        </div>
                    </div>
                    <div class="job-form__field">
                        <div class="job-form__editor-field-label">
                            Reference
                        </div>
                        <div 
                            class="job-form__reference"
                            v-for="(reference, index) in references"
                            :key="reference.id">
                            <EditorField 
                                class="job-form__reference-input"
                                v-model="references[index].reference"/>
                            <div class="job-form__reference-btns">
                                <div
                                    :class="{disabled: references.length <= 1}"
                                    class="job-form__reference-remove" 
                                    @click="removeReference(index)"
                                    @mouseenter="references[index].showRemoveTooltip = true"
                                    @mouseleave="references[index].showRemoveTooltip = false"
                                >
                                    <Tooltip v-if="references[index].showRemoveTooltip">
                                        Remove Reference
                                    </Tooltip>
                                    <Icon
                                        class="job-form__remove-reference-icon"
                                        type="close"
                                    />
                                </div>
                                <div 
                                    v-if="references.length === index + 1"
                                    class="job-form__reference-add" 
                                    @click="addReference()"
                                    @mouseenter="references[index].showAddTooltip = true"
                                    @mouseleave="references[index].showAddTooltip = false"
                                >
                                    <Tooltip v-if="references[index].showAddTooltip">
                                        Add Reference
                                    </Tooltip>
                                    <Icon 
                                        class="job-form__add-reference-icon"
                                        type="addCircle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="job-form__job-questions">
                <div class="job-form__section-title job-form__job-questions-title">
                    <Highlight />
                    Job Questions
                </div>
                <div>
                    <PocketRadio
                        label="Job Function"
                        v-model="selectedJobFunction"
                        :data="jobOptions"
                        :disabled="job">
                    </PocketRadio>
                </div>
            </div>
            <div
                v-if="isCreateQuestions || isReviewQuestions"
                class="job-form__extra-options-container"
            >
                <div class="job-form__extra-options-head">
                    {{ isCreateQuestions ? 'Create' : 'Review' }} Job Options
                </div>
                <CheckboxOptions
                    label="Allow Scenarios"
                    v-model="allowScenarios"
                    :disabled="disableAllowScenarios"
                />
                <CheckboxOptions
                    v-if="isReviewQuestions"
                    label="Reset Community Stats"
                    v-model="resetQuestionMetrics"
                    :disabled="job"
                />
                <CheckboxOptions
                    label="Scan for plagiarism"
                    v-model="checkJobPlagiarism"
                /> 
            </div>
            <div
                class="job-form__create-job-options"
                v-if="isCreateQuestions || isReviewQuestions"
            >
                <div class="job-form__extra-options-head">Supplemental Info Labels</div>
                <CheckboxOptions
                    label="Case Study"
                    v-model="supplementalInfoLabels.caseStudy.checked"
                    :disabled="disabledSupplementalInfoLabelSet.has('caseStudy')"
                />
                <CheckboxOptions
                    label="Passage"
                    v-model="supplementalInfoLabels.passage.checked"
                    :disabled="disabledSupplementalInfoLabelSet.has('passage')"
                />
                <CheckboxOptions
                    label="Patient Data"
                    v-model="supplementalInfoLabels.patientData.checked"
                    :disabled="disabledSupplementalInfoLabelSet.has('patientData')"
                />
                <CheckboxOptions
                    label="Scenario"
                    v-model="supplementalInfoLabels.scenario.checked"
                    :disabled="disabledSupplementalInfoLabelSet.has('scenario')"
                />
            </div>
            <div v-if="isCreateQuestions || isReviewQuestions">
                <div class="job-form__extra-options-head">Question Types</div>
                <Errors 
                    class="job-form__extra-options-errors"
                    :errors="validationMessages"
                    v-if="validationMessages.length"  
                />
                <CheckboxOptions 
                    v-model="allowedQuestionTypes.mcq.allowed"
                    :disabled="disabledQuestionTypeSet.has('mcq')"
                >
                    <template #labelContent>
                        <span class="job-form__question-type-acronym">MC</span> Multiple Choice
                    </template>
                </CheckboxOptions>
                <CheckboxOptions
                    v-model="allowedQuestionTypes.mcr.allowed"
                    :disabled="disabledQuestionTypeSet.has('mcr')"
                >
                    <template #labelContent>
                        <span class="job-form__question-type-acronym">MCR</span> Multiple Correct Response
                    </template>
                </CheckboxOptions>
                <CheckboxOptions
                    v-if="allowedQuestionTypes.mcb"
                    v-model="allowedQuestionTypes.mcb.allowed"
                    :disabled="disabledQuestionTypeSet.has('mcb')"
                >
                    <template #labelContent>
                        <span class="job-form__question-type-acronym">mCB</span> Matrix Checkbox
                    </template>
                </CheckboxOptions>
                <CheckboxOptions
                    v-if="allowedQuestionTypes.mrb"
                    v-model="allowedQuestionTypes.mrb.allowed"
                    :disabled="disabledQuestionTypeSet.has('mrb')"
                >
                    <template #labelContent>
                        <span class="job-form__question-type-acronym">mRB</span> Matrix Radio Button
                    </template>
                </CheckboxOptions>
                <CheckboxOptions
                    v-model="allowedQuestionTypes.tf.allowed"
                    :disabled="disabledQuestionTypeSet.has('tf')"
                >
                    <template #labelContent>
                        <span class="job-form__question-type-acronym">TF</span> True/False
                    </template>
                </CheckboxOptions>
                <FormGroup
                    v-if="allowedQuestionTypes.mcq.allowed"
                    label="MC: Default number of distractors"
                    class="col-1"
                >
                    <TextField
                        :modelValue="allowedQuestionTypes.mcq.numDistractors"
                        @update:modelValue="allowedQuestionTypes.mcq.numDistractors = Number($event)"
                    />
                </FormGroup>
                <FormGroup v-if="!isReviewQuestions && !isLoading && targetExam">
                    <QuestionTemplates
                        :knowledge-areas="mappedKnowledgeAreas"
                        :loaded-question-templates="questionTemplates"
                        :showSubtopicsHeader="subtopicData.length"
                        @change="updatedQuestionTemplates"
                        @errorCountChange="updatedSubtopicCountErrors"
                    />
                </FormGroup>
            </div>
            <ImportField
                v-if="isImportQuestions && !job"
                :exam="selectedExam"
                :exam-questions="examQuestions"
                :knowledge-areas="knowledgeAreas.length && knowledgeAreas || knowledgeAreaDrafts"
                @change="importedQuestions = $event"
            />
            <div
                v-if="!isLoading && 
                    (isReviewQuestions || (questions.length && $route.params.jobId && isImportQuestions))"
                label="Review Questions"
            >
                <List
                    v-if="listOptions.listData"
                    store-name="jobQuestionsList"
                    :list-options="listOptions"
                    :selectable="!job"
                    :is-clickable="false"
                    :pre-selected-items="selectedListData"
                    :list-unit="'Question'"
                    @select="selectQuestionDrafts"
                    @itemClicked="reviewQuestionClicked"
                    @activeFiltersChanged="updateActiveFilters"
                    class="job-form__review-list"
                >
                    <template #filterTypeDropdownListItem="{ item }">
                        <template v-if="item.label === 'Serial'">
                            <div class="job-form__filter-type-dropdown-item-container">
                                <div class="job-form__filter-type-dropdown-section-title">
                                    TABLE COLUMN
                                </div>
                                <div class="job-form__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                        <template v-if="item.label === 'Supplemental Info'">
                            <div class="job-form__filter-type-dropdown-item-container">
                                <div class="job-form__filter-type-dropdown-section-title">
                                    QUESTION FIELDS
                                </div>
                                <div class="job-form__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                        <template v-if="item.label === 'Selected'">
                            <div class="job-form__filter-type-dropdown-item-container">
                                <div class="job-form__filter-type-dropdown-section-title">
                                    T/F FILTERS
                                </div>
                                <div class="job-form__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <template #filterTextDropdownListItem="{ item, field }">
                        <template v-if="field.propName === 'type'">
                            <div
                                v-if="item.label === 'MC'"
                                class="job-form__question-type-dropdown-item"
                            >
                                <div class="job-form__question-type-dropdown-item-label">
                                    MC
                                </div>
                                <div>
                                    Multiple Choice Question
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'MCR'"
                                class="job-form__question-type-dropdown-item"
                            >
                                <div class="job-form__question-type-dropdown-item-label">
                                    MCR
                                </div>
                                <div>
                                    Multiple Correct Response
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'mCB'"
                                class="job-form__question-type-dropdown-item"
                            >
                                <div class="job-form__question-type-dropdown-item-label">
                                    mCB
                                </div>
                                <div>
                                    Matrix Checkbox
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'mRB'"
                                class="job-form__question-type-dropdown-item"
                            >
                                <div class="job-form__question-type-dropdown-item-label">
                                    mRB
                                </div>
                                <div>
                                    Matrix Radio Button
                                </div>
                            </div>
                            <div
                                v-if="item.label === 'TF'"
                                class="job-form__question-type-dropdown-item"
                            >
                                <div class="job-form__question-type-dropdown-item-label">
                                    TF
                                </div>
                                <div>
                                    True/False
                                </div>
                            </div>
                        </template>
                    </template>
                    <template #tableColumnLabelText="{ column }">
                        <template v-if="column.propName === 'type'">
                            Type
                        </template>
                        <template v-else-if="column.propName === 'percentCorrect'">
                            Correct
                        </template>
                    </template>
                    <template #tableCellValue="{ row, column }">
                        <template v-if="!job && column.propName === 'serial'">
                            <div class="job-form__checkbox-serial">
                                <Checkbox
                                    :modelValue="selectedQuestions.includes(row.objectId)"
                                    class="job-form__checkbox"/>
                                <div class="job-form__serial">
                                    <OverflowTooltip>
                                        {{ row.serial }}
                                    </OverflowTooltip>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="job && column.propName === 'serial'">
                            <div class="job-form__serial">
                                <OverflowTooltip>
                                    {{ row.serial }}
                                </OverflowTooltip>
                            </div>
                        </template>
                    </template>
                    <template #prependListUnitText>
                        Active, Enabled Exam
                    </template>
                    <template #emptyStateTitle="{ activeFilterPropNames }">
                        <template v-if="activeFilterPropNames.length === 1">
                            <template v-if="activeFilterPropNames.includes('isDisabled')">
                                0 Disabled Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('selected')">
                                0 Selected Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isSpecial')">
                                0 Free Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isArchived')">
                                0 Archived Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('explanationImage')">
                                0 Questions with Explanation Image
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('questionImage')">
                                0 Questions with Question Image
                            </template>
                        </template>
                    </template>
                    <template #emptyStateMessage="{ searchAll, activeFilterPropNames }">
                        <template v-if="searchAll">
                            All column fields are searchable except for answered and correct.
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 1">
                            <template v-if="activeFilterPropNames.includes('isDisabled')">
                                Disabled questions are questions already in another job.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('selected')">
                                No questions are selected for this job.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isSpecial')">
                                There are no free questions in this exam.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isArchived')">
                                There are no archived questions in this exam.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('explanationImage')">
                                No questions in this exam include an explanation image.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('questionImage')">
                                No questions in this exam include an image in the question prompt.
                            </template>
                        </template>
                    </template>
                </List>
            </div>
        </div>
        <ButtonFooter v-if="!job && !$route.params.jobId">
            <template
                #rightSide 
            >
                <div class="job-form__right-side-buttons">
                    <PocketButton
                        class="job-form__cancel-button"
                        type="secondary"
                        @click="cancelJobFormClicked"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        :disabled="!canSubmitForm"
                        @click="submitCreateJobForm"
                    >
                        Create Job
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
        <ButtonFooter v-if="job && $route.params.jobId">
            <template
                #leftSide 
            >
                <div class="job-form__left-side-buttons">
                    <PocketButton
                        type="primary-red"
                        @click="submitDeleteJob"
                    >
                        Delete Job
                    </PocketButton>
                </div>
            </template>
            <template 
                #center 
            >
                <div class="job-form__center-buttons">
                    <ChatButton
                        v-if="!isLoading && job"
                        class="job-form__chat-button"
                        :job-id="$route.params.jobId"
                    />
                </div>
            </template>
            <template
                #rightSide 
            >
                <div class="job-form__right-side-buttons">
                    <PocketButton
                        class="job-form__cancel-button"
                        type="secondary"
                        @click="cancelJobFormClicked"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        :disabled="!canSubmitForm || job.isCompleted"
                        @click="submitCreateJobForm"
                    >
                        Save Job
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts" src="@/components/Jobs/JobForm/JobForm.ts"></script>

<style lang="scss" scoped>
.job-form {
    padding: $base;
    width: 100%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 200px;

    &--prod-env {
        background-color: $charcoal;
    }

    .disabled {
        opacity: 0.5;
    }

    &__loading-spinner {
        position: absolute;
        :deep(.uikit-loading) {
            border-color: $banana-bread;
            border-top-color: $slate;
        }
    }

    &__loading-message {
        position: fixed;
        width: 413px;
        height: 41px;
        top: 78px;
        left: 42%;
        border-radius: 6px;
        padding: 8px 12px;
        background-color: $brand-black;
        color: #fff;
        font-size: 15px;
        line-height: 24px;
        text-align: right;
    }

    &__existing-job {
        margin-top: 25px;
        margin-bottom: 44px;
    }

    &__existing-job-name {
        font-size: 30px;
        line-height: 36px;
        color: $brand-black;
        font-weight: 600;
        margin-bottom: 4px;
    }

    &__existing-job-subtext {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
    }

    &__section-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        position: relative;
        width: fit-content;
        margin-bottom: 15px;

        svg {
            position: absolute;
            left: -8px;
            top: 15px;
            width: calc(100% + 20px);
            height: 27px;
        }
    }

    &__exam-option {
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    &__exam-option-composite-key {
        size: 13px;
        color: $ash;
    }

    &__job-questions-title {
        margin-bottom: 33px;
    }

    &__progress {
        display: flex;
        justify-content: space-between;
        margin-top: 52px;
    }

    &__title-text {
        margin-bottom: 50px;
        margin-top: 75px;
        font-size: 40px;

        &--edit-job {
            margin-top: 20px;
        }
    }

    :deep(.uikit-radio__item) {
        margin-left: 16px;
        width: 240px;
    }
    
    :deep(.uikit-radio__label) {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: $brand-black;
        margin-top: 18px;
        margin-bottom: 12px;
    }

    :deep(.uikit-checkbox-option) {
        margin-left: 16px;
        width: 320px;
    }

    h3 {
        line-height: 27px;
        font-size: 20px;
        font-weight: 600;
    }

    &__progress-bar {
        width: 40%;
    }

    :deep(.uikit-select__list) {
        max-height: 342px;
    }

    :deep(.uikit-select__item[data-value="Serial"]),
    :deep(.uikit-select__item[data-value="Supplemental Info"]),
    :deep(.uikit-select__item[data-value="Selected"]) {
        margin-top: 31px;
    }

    &__filter-type-dropdown-item-container {
        position: relative;
    }

    &__filter-type-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    &__question-type-dropdown-item {
        display: flex;
    }

    &__question-type-dropdown-item-label {
        font-weight: 600;
        margin-right: 4px;
    }

    :deep(.list__result-rows-container) {
        width: 1230px;
    }

    :deep(.list__result-count) {
        margin: 0;
    }

    &__checkbox {
        margin-right: 15px;
        min-width: 18px;
    }

    &__checkbox-serial {
        display: flex;
        align-items: center;
    }

    &__field {
        margin-top: 20px;
    }

    &__writer-select, &__editor-select, &__lead-select {
        margin-top: 20px;
        :deep(.uikit-select__list) {
            padding: 0;
        }
    }

    &__job-type {
        width: 320px;
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
    }

    &__job-details {
        width: 796px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &__keyword-select {
        width: 320px;
        :deep(.uikit-select__list) {
            padding-bottom: 7px;
        }
        :deep(.uikit-select__item) {
            padding: 4px 11px;
        }
    }

    &__keyword-value,
    &__keyword-option {
        &--bold {
            font-weight: 700;
        }
    }

    &__notes-and-links-editor {
        :deep(.editor) {
            min-height: 88px;
        }

        :deep(.ck-content) {
            padding-bottom: 30px;
        }

        :deep(.ck-content a) {
            font-weight: 600;
            color: $brand-blue;
        }

        :deep(.ck-content p) {
            line-height: 14px;
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }

    &__notes-and-links-placeholder {
        line-height: 16px;
        font-size: 15px;
        position: relative;
        color: $slate;
        left: 10px;
        top: -24px;
        margin-bottom: -18px;
    }

    &__reference {
        font-size: 14px;
        min-height: 64px;
        position: relative;

        &:not(:nth-child(1 of .job-form__reference)) {
            margin-top: 6px;
        }
    }

    &__reference-input {
        font-size: 14px;
        
        :deep(.editor) {
            min-height: 64px;
        }
    }

    &__reference-btns {
        display: flex;
        align-items: center;
        position: absolute;
        left: 404px;
        top: -20px;
        height: 64px;
        line-height: 34px;
        margin-top: 18px;
    }

    &__reference-remove,
    &__reference-add {
        display: inline-block;
        position: relative;
        margin-left: 11px;
        height: 20px;

        svg {
            cursor: pointer;
            color: $brand-blue;
        }

        &:hover svg {
            color: $brand-black;
        }

        .uikit-tooltip {
            position: absolute;
            top: -37px;
            left: 10px;
        }
    }

    &__left-col-job-details {
        margin-right: 80px;
    }

    &__right-col-job-details {
        width: 396px;
    }

    &__editor-field-label {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin: 18px 0 4px 12px;
        color: $pickled-bluewood;
    }

    &__job-questions {
        margin-top: 80px;
    }

    &__extra-options-head {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin: 36px 0 12px 0;
    }

    &__extra-options-errors {
        width: 596px;
        margin-bottom: 12px;
    }

    &__question-type-acronym {
        font-weight: 600;
    }

    &__actions {
        margin: 20px 0;

        label {
            font-size: 12px;
            display: block;
            margin-bottom: 6px;
        }
    }

    &__buttons {
        button {
            margin-right: 10px;
        }
    }

    &__buttons-group {
        margin-right: 10px;
        display: inline-block;

        button {
            margin-right: 0;
            padding: 10px 15px;
            border-radius: 0;

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
            }
        }
    }

    &__allowed-types-label {
        display: block;
        font-size: 14px;
        line-height: 22px;
        position: relative;
        margin-left: 8px;
        margin-bottom: 8px;
        font-weight: 600;
    }

    &__review-list {
        margin-top: 60px;
    }

    &__allowed-types {
        display: block;
        margin: 5px 5px 6px 5px;
        font-size: 14px;

        input {
            margin-right: 10px;
        }
    }

    &__left-side-buttons {
        margin-left: 18px;
        position: absolute;
    }

    &__center-buttons {
        position: absolute;
        left: calc(50% - 50px);

        &:hover {
            border-radius: 4px;
            background-color: mix($barely-background, black, 96%);
        }

        :deep(.uikit-btn) {
            &:hover {
                background-color: transparent;
            }
        }
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__cancel-button {
        margin-right: 8px;
    }
}
</style>