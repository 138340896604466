import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

const initAmplitude = () => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey) {
        const ampliInitConfig: amplitude.Types.BrowserOptions = {
            defaultTracking: {
                attribution: {
                    /**
                     * See ampli docs here
                     * https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#exclude-referrers
                     *  */
                    excludeReferrers: [],
                },
            },
        }

        // Instrument amplitude with session replay tracking
        const sessionReplayTracking = sessionReplayPlugin({
            sampleRate: 1, // 100% sample rate as indicated in ticket
        })
        amplitude.add(sessionReplayTracking)

        amplitude.init(amplitudeApiKey, ampliInitConfig)
    }
}

const amplitudeTrack = (eventName: string, eventProperties?: Record<string, string | number | boolean | undefined>) => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey) {
        amplitude.track(eventName, eventProperties)
    }
}

const amplitudeReset = () => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey) {
        amplitude.reset()
    }
}

const setAmplitudeUser = ({ userId, email, createdAt }: {
    userId: string
    email: string
    createdAt: string   // ISO date string
}) => {
    const amplitudeApiKey = import.meta.env.VUE_APP_AMPLITUDE_API_KEY
    if (amplitudeApiKey) {
        amplitude.setUserId(userId)
        
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('email', email)
        identifyEvent.set('createdAt', createdAt)

        amplitude.identify(identifyEvent)
    }
}

export default {
    initAmplitude,
    amplitudeTrack,
    amplitudeReset,
    setAmplitudeUser,
}
