<template>
    <div class="question-sidebar">
        <div
            v-if="userRole !== 'Editor'"
            class="question-sidebar__create-section"
        >
            <div
                v-if="job.jobFunction === 'Create Questions'"
                class="question-sidebar__head"
                :style="{ paddingBottom: showQuestionsTable ? '14px' : '22px'}"
            >
                <div class="question-sidebar__head-title">
                    Questions to Create
                </div>
                <PocketButton 
                    class="question-sidebar__head-toggle-button"
                    type="secondary"
                    @click="showQuestionsTable = !showQuestionsTable"
                >
                    {{ showQuestionsTable ? 'Hide' : 'Show' }}
                </PocketButton>
            </div>
            <div v-if="job.jobFunction === 'Create Questions' && showQuestionsTable">
                <Table
                    :columns="tableColumns"
                    :rows="tableRows"
                    :show-header="false"
                    :tableStyles="{
                        backgroundColor: brandColors.moonlitOcean,
                        color: brandColors.fog,
                    }"
                    :tableContentStyles="{ maxHeight: '541px', overflow: 'auto' }"
                    :tableRowsStyles="{ overflowY: 'visible' }"
                    :tableRowStyles="{
                        fontSize: '13px',
                        padding: '8px 12px 7px 14px',
                        lineHeight: '15px',
                        borderColor: brandColors.ash,
                        cursor: 'default',
                        height: 'fit-content',
                        paddingLeft: '34px',
                    }"
                    :tableSubrowsStyles="{
                        fontSize: '12px',
                        padding: '7px 12px 6px 34px',
                        borderColor: brandColors.ash,
                        cursor: 'default',
                        lineHeight: '15px',
                    }"
                    :tableColumnLabelsStyles="{
                        color: brandColors.fog,
                        paddingLeft: '12px',
                        paddingRight: '13px',
                        borderColor: brandColors.ash,
                    }"
                    @rowEnter="rowEnter"
                    @rowLeave="rowLeave"
                >
                    <template #tableCellValue="{ row, column }">
                        <template v-if="row.subrows && row.subrows.length && column.propName === 'subject'">
                            <div class="question-sidebar__prefix-number">{{ row.prefixNumber }}</div>
                            <div
                                @mouseenter="isHoveringExpandableCell = true"
                                @mouseleave="isHoveringExpandableCell = false"
                                @click.stop="toggleSubrows(row, column)"
                                :style="{
                                    color: rowHoverId === row.id && isHoveringExpandableCell 
                                        ? brandColors.bananaBread 
                                        : brandColors.fog,
                                    cursor: 'pointer',
                                }"> 
                                <div class="question-sidebar__subrow-toggle-cell">
                                    {{ breakSubjectName(row.subject).nameWithoutLastWord }}
                                    <span :style="{ display: 'inline-block'}">
                                        {{ breakSubjectName(row.subject).lastWord }}
                                        <Icon
                                            class="question-sidebar__arrow-icon" 
                                            type="accordionArrow"
                                            :class="{ 'question-sidebar__arrow-icon--expanded': row.displaySubrows}"
                                        />
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="column.propName === 'subject'">
                            <div class="question-sidebar__prefix-number">{{ row.prefixNumber }}</div>
                            <div>{{ row.subject }}</div>
                        </template>
                        <template v-if="column.propName === 'count'">
                            <span
                                :ref="row.id"
                                :class="{
                                    'question-sidebar__active-item': row.id === activeItem
                                        || collapsedRowHasAnActiveSubtopic(row.id)
                                }"
                            >
                                {{
                                    row.id === activeItem || collapsedRowHasAnActiveSubtopic(row.id)
                                        ? parseInt(row.count.split('/')[0]) + 1
                                        : row.count.split('/')[0]
                                }}<div
                                    class="question-sidebar__active-item-highlight"
                                    v-if="row.id === activeItem
                                        || collapsedRowHasAnActiveSubtopic(row.id)" />
                            </span>
                            <span class="question-sidebar__active-item-count">
                                {{ `/${row.count.split('/')[1]}` }}
                            </span>
                        </template>
                    </template>
                    <template #tableSubrowCellValue="{ subrow, column }">
                        <template v-if="column.propName === 'subject'">
                            <div>{{ subrow.subject }}</div>
                        </template>
                        <template v-if="column.propName === 'count'">
                            <span
                                :ref="subrow.id"
                                :class="{
                                    'question-sidebar__active-item': subrow.id === activeItem
                                }"
                            >
                                {{ subrow.id === activeItem
                                    ? parseInt(subrow.count.split('/')[0]) + 1
                                    : subrow.count.split('/')[0]
                                }}<div
                                    class="question-sidebar__active-item-highlight"
                                    v-if="subrow.id === activeItem
                                        || collapsedRowHasAnActiveSubtopic(subrow.id)" />
                            </span>
                            <span>
                                {{ `/${subrow.count.split('/')[1]}` }}
                            </span>
                        </template>
                    </template>
                </Table>
            </div>
        </div>
        <div class="question-sidebar__questions-preview-section">
            <div class="question-sidebar__questions-preview-header">
                <div> {{ `${currentFilter.header
                    || currentFilter.label} (${mappedQuestionDrafts[currentFilter.value]?.length || 0})` }} </div>
                <PocketButton
                    type="icon-yellow"
                    :icon="currentFilter.value !== 'all' ? 'filterActive' : 'filter'"
                    @click="showFilterDropdown = !showFilterDropdown"
                    @keypress.enter="showFilterDropdown = !showFilterDropdown"
                    @mousedown.prevent
                />
                <FilterDropdown
                    class="question-sidebar__questions-preview-filter-dropdown"
                    v-if="showFilterDropdown"
                    :filterOptions="filterOptions"
                    :selectedFilter="currentFilter"
                    @filterChanged="updateFilter"
                    @close="showFilterDropdown = false"
                />
            </div>
            <div class="question-sidebar__questions-preview-list">
                <div
                    v-if="userRole === 'Writer'
                        && (unsavedQuestionDraftPrompt || unsavedQuestionDraftSubject)
                        && job.totalNewQuestionTemplates
                        && questionDrafts.length < job.totalNewQuestionTemplates"
                    tabindex="0"
                    class="question-sidebar__question-preview"
                    :class="{
                        'question-sidebar__question-preview--selected': isNewQuestionDraftOpen,
                        'question-sidebar__question-preview--hover-enabled': !isNewQuestionDraftOpen,
                    }"
                    @click="openUnsavedQuestionDraftPrompt"
                >
                    <div 
                        v-if="unsavedQuestionDraftSubject"
                        class="question-sidebar__question-preview-subject"
                        :class="{
                            'question-sidebar__question-preview-subject--selected': isNewQuestionDraftOpen,
                        }"
                    >
                        <div class="question-sidebar__preview-subject-name">
                            {{ unsavedQuestionDraftSubject }}
                        </div>
                        <div class="question-sidebar__preview-tag-draft">
                            Unsaved Draft
                        </div>
                    </div>
                    <div
                        v-if="unsavedQuestionDraftPrompt"
                        class="question-sidebar__question-preview-prompt"
                        v-html="unsavedQuestionDraftPrompt.replace(/<p>/g, '').replace(/<\/p>/g, '')"
                    >
                    </div>
                </div>
                <div
                    v-for="q in mappedQuestionDrafts[currentFilter.value]"
                    :key="q.id"
                    tabindex="0"
                    class="question-sidebar__question-preview"
                    :class="{
                        'question-sidebar__question-preview--selected': q.serial === currentQuestionSerial,
                        'question-sidebar__question-preview--hover-enabled': q.serial !== currentQuestionSerial,
                    }"
                    @click="questionClicked(q.id)"
                >
                    <div
                        class="question-sidebar__question-preview-subject"
                        :class="{
                            'question-sidebar__question-preview-subject--selected': q.serial === currentQuestionSerial
                        }"
                        :ref="`preview_${q.serial}`"
                    >
                        <div class="question-sidebar__preview-subject-name">
                            {{ q.subjectName }}
                        </div>
                        <div v-if="q.isFlagged || q.unsubmitted">
                            <div v-if="q.isFlagged">
                                <Icon type="flagFeedback" class="question-sidebar__preview-icon"/>
                            </div>
                            <div
                                v-else-if="q.unsubmitted && !q.isFlagged"
                                class="question-sidebar__preview-tag-unsubmitted"
                            >
                                Unsubmitted
                            </div>
                        </div>
                    </div>
                    <div 
                        class="question-sidebar__question-preview-prompt"
                        v-html="q.prompt.replace(/<p>/g, '').replace(/<\/p>/g, '')"
                    />
                </div>
                <div v-if="currentFilter.value
                    && !mappedQuestionDrafts[currentFilter.value].length
                    && currentFilter.value !== 'all'"
                >
                    <EmptyState
                        :title="currentFilter.value === 'flagged' && userRole === 'Writer' ? '0 Flagged Questions' : ''"
                        :message="emptyState?.message"
                        :isDarkMode="true"
                        class="question-sidebar__empty-state"
                    >
                        <template #icon>
                            <template v-if="emptyState?.icon === 'checklist'">
                                <Icon type="activity"></Icon>
                            </template>
                            <template v-if="emptyState?.icon === 'flag'">
                                <Icon type="flagFeedback" class="question-sidebar__flag-icon"></Icon>
                            </template>
                            <template v-if="emptyState?.icon === 'zeroQ'">
                                <ZeroQuestionsIcon />
                            </template>
                        </template>
                    </EmptyState>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'
import UIKit, { type ITableColumn, type TTableRow } from '@pocketprep/ui-kit'
import type { CMS } from '@pocketprep/types'
import type { IKnowledgeArea } from '@/store/knowledgeAreaDrafts/types'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import questionDraftsModule from '@/store/questionDrafts/module'
import FilterDropdown from '@/components/FilterDropdown.vue'
import ZeroQuestionsIcon from '@/assets/img/questionDraftSidebar/ZeroQuestionsIcon.vue'

type TQuestionRow = {
    id: string
    serial: string
    prompt: string
    isFlagged: boolean
    unsubmitted: boolean
    subjectName?: string
    scenarioId?: string
}
type TQuestionsLib = {
    all: TQuestionRow[]
    writer: TQuestionRow[]
    editor: TQuestionRow[]
    completed: TQuestionRow[]
    flaggedToWriter: TQuestionRow[]
    flagged: TQuestionRow[]
    [key: string]: TQuestionRow[]
}

@Component({
    components: {
        PocketLink: UIKit.Link,
        Table: UIKit.Table,
        OverflowTooltip: UIKit.OverflowTooltip,
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
        EmptyState: UIKit.EmptyState,
        FilterDropdown,
        ZeroQuestionsIcon,
    },
})
export default class QuestionDraftSidebar extends Vue {
    @Prop() readonly job!: CMS.Cloud.JobWithMetrics
    @Prop() readonly kaDrafts: (CMS.Class.KnowledgeAreaDraftJSON | IKnowledgeArea)[] = []
    @Prop() readonly userRole!: 'Writer' | 'Editor' | 'Admin'
    @Prop() readonly currentQuestionSerial!: string
    @Prop() readonly unsavedQuestionDraftPrompt!: string
    @Prop() readonly unsavedQuestionDraftSubject?: string
    @Prop() readonly currentSubjectId!: string
    @Prop() readonly currentSubtopicId?: string
    @Prop({ default: [] }) readonly questionDrafts!: CMS.Class.QuestionDraftJSON[]
    @Prop() readonly showCreateQuestionsOnLoad!: boolean

    tableColumns: ITableColumn [] = 
        [{
            name: 'Subject',
            propName: 'subject',
            styles: {
                width: '170px',
                marginRight: '0',
                letterSpacing: '-0.1px',
            },
            labelStyles: {
                fontSize: '12px',
                width: '190px',
            },
            isSortDisabled: true,
        }, 
        {
            name: 'Count',
            propName: 'count',
            styles: {
                width: '65px',
                textAlign: 'right',
                color: BrandColors.white,
                fontWeight: '600',
            },
            labelStyles: {
                color: BrandColors.fog,
                fontWeight: '500',
                fontSize: '12px',
            },
            isSortDisabled: true,
        }]

    expandedRowIdSet: Set<string | number> = new Set()
    brandColors = BrandColors
    error: string | null = null
    rowHoverId: string | null = null
    isHoveringExpandableCell = false
    showQuestionsTable = true
    currentFilter: { label: string; value: string; header?: string } = { label:'', value:'', header: '' }
    showFilterDropdown = false

    get isNewQuestionDraftOpen () {
        return this.$route.name === 'question-draft-create' && this.$route.query.job
    }

    get storedUnsavedQuestionDraftPrompt () {
        return questionDraftsModule.state.unsavedQuestionDraft
    }

    get tableRows () {
        if (!this.job.questionTemplates) {
            return []
        }
        const rows: TTableRow [] = this.job.questionTemplates.map((item, index) => {
            const prefixNumber = index + 1
            const styles: { [x: string]: string } = {}
            const kadSubtopics = this.kaDrafts.find(kad => 
                'objectId' in kad && kad.objectId === item.knowledgeAreaDraftId)?.subtopics
            return {
                prefixNumber,
                id: item.knowledgeAreaDraftId,
                subject: this.knowledgeAreaName(item.knowledgeAreaDraftId),
                displaySubrows: this.expandedRowIdSet.has(item.knowledgeAreaDraftId),
                subrows: item.subtopics?.map(subtopic => {
                    //TODO: When when all the old CMS jobs are completed we can switch to using only subtopic.id
                    const fetchedSubtopicId = kadSubtopics?.find(kadSubtopic => kadSubtopic.name === subtopic.name)?.id
                    return {
                        id: fetchedSubtopicId || subtopic.id,
                        subjectId: item.knowledgeAreaDraftId,
                        subject: subtopic.name,
                        count: `${this.questionsInSubtopicCompletedCount(fetchedSubtopicId
                            || subtopic.id)}/${subtopic.count}`,
                        isSubtopicRow: true,
                        styles,
                    }
                }),
                count: `${this.questionsCompletedCount(item.knowledgeAreaDraftId)}/${item.count}`,
                styles,
            }
        })
        rows.push({
            id: 'total',
            subject: 'Total',
            count: `${this.questionDrafts?.length}/${this.totalCount?.totalCount || 0}`,
            styles: {
                fontWeight: '600',
                color: BrandColors.white,
                position: 'sticky',
                bottom: '0',
                backgroundColor: `${BrandColors.moonlitOcean}`,
                paddingLeft: '14px',
                zIndex: '2',
                paddingTop: '9px',
                paddingBottom: '9px',
            },
        })
        return rows
    }

    get filterOptions () {
        const qDrafts = this.mappedQuestionDrafts
        if (this.userRole === 'Writer') {
            return [
                { label: 'All Questions', value: 'all', count: qDrafts.all.length || 0 }, 
                { label: 'Submitted to Editor', value: 'editor', header: 'Submitted Questions',
                    count: qDrafts.editor.length || 0 },
                { label: 'Unsubmitted', value: 'writer', header: 'Unsubmitted Questions',
                    count: qDrafts.writer.length || 0 },
                { label: 'Flagged', value: 'flagged', header: 'Flagged Questions',
                    count: qDrafts.flaggedToWriter.length || 0 },
            ]
        } else if (this.userRole === 'Editor') {
            return [
                { label: 'To Review', value: 'editor', header: 'Questions to Review',
                    count: qDrafts.editor.length || 0 },
                { label: 'Approved', value: 'completed', header: 'Approved Questions',
                    count: qDrafts.completed.length || 0,
                },
                { label: 'Flagged to Writer', value: 'flaggedToWriter', count: qDrafts.flaggedToWriter.length || 0,
                },
            ]
        } else if (this.userRole === 'Admin') {
            return [
                { label: 'Approved', value: 'completed', count: qDrafts.completed?.length || 0 }, 
                { label: 'Editor', value: 'editor', count: qDrafts.editor?.length || 0 },
                { label: 'Writer', value: 'writer', count: qDrafts.writer?.length || 0 },
                { label: 'Flagged', value: 'flagged', header: 'Flagged to Writer',
                    count: qDrafts.flaggedToWriter?.length || 0 },
            ]
        } else {
            return []
        }
    }

    get totalCount () {
        return this.job.questionTemplates?.reduce((acc: { completedCount: number; totalCount: number}, curr) => {
            acc.completedCount += this.questionsCompletedCount(curr.knowledgeAreaDraftId)
            acc.totalCount += curr.count
            return acc
        }, { completedCount: 0, totalCount: 0 })
    }

    get mappedQuestionDrafts () {
        return this.questionDrafts.reduce<TQuestionsLib>((acc, question) => {
            const id = question.objectId
            const serial = question.serial || question.objectId
            const prompt = question.prompt || ''
            const status = question.jobStatus
            const isFlagged = question.isFlagged
            const subjectName = question.knowledgeAreaDraft?.objectId
            && this.knowledgeAreaName(question.knowledgeAreaDraft?.objectId)
            const unsubmitted = question.jobStatus === 'Writer'
            const scenarioId = question.questionScenarioDraft?.objectId

            const questionRow: TQuestionRow = {
                id,
                serial,
                prompt,
                isFlagged,
                subjectName,
                unsubmitted,
                scenarioId,
            }

            if (status === 'Writer') {
                acc.writer?.push(questionRow)
            }
            if (status === 'Editor') {
                acc.editor?.push(questionRow)
            }
            if (status === 'Completed') {
                acc.completed?.push(questionRow)
            }
            if (isFlagged) {
                acc.flagged?.push(questionRow)
            }
            if (isFlagged && status === 'Writer') {
                acc.flaggedToWriter.push(questionRow)
            }

            acc.all?.push(questionRow)
            return acc
        }, { all: [], flagged: [], writer: [], editor: [], completed: [], flaggedToWriter: [] })
    }

    get filterSerialSets () {
        return Object.keys(this.mappedQuestionDrafts).reduce((acc, filterOptionValue) => {
            const filterSerialList = this.mappedQuestionDrafts[filterOptionValue].map(qDraft => qDraft.serial)
            acc[filterOptionValue] = new Set(filterSerialList)
            return acc
        }, {} as Record<string, Set<string>>)
    }

    get emptyState ():{ message: string; icon: 'zeroQ' | 'flag' | 'checklist'} | null {
        const currentFilter = this.currentFilter.value
        if (currentFilter === 'editor') {
            if (this.userRole === 'Writer') {
                return {
                    message: '0 questions have been submitted to the Editor.',
                    icon: 'zeroQ',
                }
            } else if (this.userRole === 'Editor' || this.userRole === 'Admin') {
                return {
                    message: 'All questions ready to review have been reviewed.',
                    icon: 'checklist',
                }
            } else {
                return null
            }
        } else if (currentFilter === 'writer') {
            if (this.userRole === 'Writer') {
                return {
                    message: 'All written questions have been sent to the editor.',
                    icon: 'checklist',
                }
            } else if (this.userRole === 'Editor') {
                return {
                    message: '0 questions flagged for the Writer.',
                    icon: 'flag',
                }
            } else if (this.userRole === 'Admin') {
                return {
                    message: '0 questions for the Writer to review.',
                    icon: 'zeroQ',
                }
            } else {
                return null
            }
        } else if (currentFilter === 'flagged') {
            if (this.userRole === 'Writer') {
                return {
                    message: 'Flagged questions are questions that have been sent back to the writer to review.',
                    icon: 'flag',
                }
            } else if (this.userRole === 'Editor' || this.userRole === 'Admin') {
                return {
                    message: '0 questions flagged for the Writer.',
                    icon: 'flag',
                }
            } else {
                return null
            }
        } else if (currentFilter === 'flaggedToWriter') {
            return {
                message: '0 questions flagged for the Writer.',
                icon: 'flag',
            }
        } else if (currentFilter === 'completed') {
            if (this.userRole === 'Editor' || this.userRole === 'Admin') {
                return {
                    message: '0 questions have been approved to the Lead.',
                    icon: 'zeroQ',
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    get activeItem () {
        if (this.$route.name !== 'question-draft-create') {
            return ''
        }
        if (this.currentSubtopicId) {
            return this.currentSubtopicId
        } else {
            return this.currentSubjectId
        }
    }

    get isQuestionInActiveFilter () {
        if (!this.currentFilter.value) {
            return false
        }
        return this.mappedQuestionDrafts[this.currentFilter.value]
            .find(qd => qd.serial === this.currentQuestionSerial) ? true : false
    }

    get nextQuestionInFilter () {
        const currentFilteredQuestions = this.mappedQuestionDrafts[this.currentFilter.value]

        if (currentFilteredQuestions.length <= 1) {
            return false
        }
        
        const currentQuestionIndex = currentFilteredQuestions
            .findIndex(question => question.serial === this.currentQuestionSerial)
        const nextFilteredQuestion = currentFilteredQuestions[currentQuestionIndex + 1]
        const firstFilteredQuestion = currentFilteredQuestions[0]
        // Prioritize the next scenario question, if available
        const currentScenarioId = currentFilteredQuestions[currentQuestionIndex]?.scenarioId
        const nextQuestionFromScenario = currentScenarioId && currentFilteredQuestions.find(question =>
            question.serial !== this.currentQuestionSerial && question.scenarioId === currentScenarioId
        )

        const nextQuestion = nextQuestionFromScenario || nextFilteredQuestion || firstFilteredQuestion

        return nextQuestion?.id || false
    }

    breakSubjectName (subjectName: string) {
        const wordsArray = subjectName.split(/\s+/)
        const lastWord = wordsArray.pop()
        const nameWithoutLastWord = wordsArray.join(' ')

        return { 
            nameWithoutLastWord,
            lastWord,
        }
    }

    knowledgeAreaName (kaId: string) {
        const knowledgeAreaDraft = this.kaDrafts.length 
            && this.kaDrafts.find(ka => !!(ka) && 'objectId' in ka && ka.objectId === kaId)
        return knowledgeAreaDraft
            ? knowledgeAreaDraft.name
            : ''
    }

    questionsCompletedCount (ka: string) {
        return (this.questionDrafts && this.questionDrafts.length)
            ? this.questionDrafts
                .filter(questionDraft => 
                    questionDraft.knowledgeAreaDraft &&
                    questionDraft.knowledgeAreaDraft.objectId === ka &&
                    !questionDraft.examDataId
                ).length
            : 0
    }

    questionsInSubtopicCompletedCount (subtopic: string | undefined) {
        if (!subtopic) {
            return 0
        }
        return (this.questionDrafts && this.questionDrafts.length)
            ? this.questionDrafts
                .filter(questionDraft => 
                    questionDraft.subtopicId &&
                    questionDraft.subtopicId === subtopic &&
                    !questionDraft.examDataId
                ).length
            : 0
    }

    updateFilter (filter: { label: string; value: string}) {
        if (this.currentFilter.value !== filter.value) {
            this.currentFilter = filter
            //select first question from the new filter
            const newSelectedQuestionId = this.mappedQuestionDrafts[this.currentFilter.value][0]?.id
            if (newSelectedQuestionId) {
                this.questionClicked(newSelectedQuestionId)
            }
        }
    }

    openUnsavedQuestionDraftPrompt () {
        this.$router.push({ 
            name: 'question-draft-create',
            query: { job: this.storedUnsavedQuestionDraftPrompt?.jobId || this.job.objectId },
        })
    }

    toggleSubrows (row: TTableRow, column: ITableColumn) {
        if (column.propName === 'subject') {
            row.displaySubrows = !row.displaySubrows
            if (row.displaySubrows) {
                this.expandedRowIdSet.clear()
                this.expandedRowIdSet.add(row.id)
            } else {
                this.expandedRowIdSet.delete(row.id)
            }
        }
    }

    rowEnter (row: TTableRow) {
        this.rowHoverId = String(row.id)
    }

    rowLeave (row: TTableRow) {
        if (this.rowHoverId === String(row.id)) {
            this.rowHoverId = null
        }
    }

    selectMatchingFilter () {
        if (!this.currentFilter.value) {
            this.currentFilter = this.filterOptions.slice(1).find(filterOption =>
                this.filterSerialSets[filterOption.value].has(this.currentQuestionSerial)
            ) || this.filterOptions[0]
        }
    }

    collapsedRowHasAnActiveSubtopic (rowId: string) {
        if (this.expandedRowIdSet.has(rowId)) {
            return false
        }
        return this.tableRows
            .find(tr => tr.id === rowId)?.subrows?.some(subrow => subrow.id === this.activeItem) || false
    }

    expandActiveSubtopic () {
        const subjectOfActiveSubtopic = this.tableRows
            .find(tr => tr.subrows?.some(subrow => subrow.id === this.activeItem))?.id

        if (subjectOfActiveSubtopic) {
            if (this.expandedRowIdSet.has(subjectOfActiveSubtopic)) {
                return
            } else {
                this.expandedRowIdSet.clear()
                this.expandedRowIdSet.add(subjectOfActiveSubtopic)
            }
        }

        const activeItemRef = this.$refs[this.activeItem] as HTMLElement | undefined
        if (activeItemRef) {
            activeItemRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    scrollToActive () {
        const selectedPreview = this.$refs[`preview_${this.currentQuestionSerial}`] as HTMLElement[] | undefined
        if (selectedPreview) {
            selectedPreview[0].scrollIntoView({ block: 'center' })
        }
    }

    mounted () {
        if (this.userRole === 'Admin' && this.$route.name === 'question-draft-create') {
            this.currentFilter = this.filterOptions.find(filterOption => filterOption.value === 'writer')
            || this.filterOptions[0]
        } else {
            this.selectMatchingFilter()
        }

        this.expandActiveSubtopic()

        setTimeout(() => {
            this.scrollToActive()
        }, 100)

        this.showQuestionsTable = this.showCreateQuestionsOnLoad
    }

    @Watch('activeItem')
    updateExpandedRow () {
        this.expandActiveSubtopic()
    }

    @Watch('isQuestionInActiveFilter')
    updateFilterIfNotMatching () {
        this.selectMatchingFilter()
    }

    @Watch('currentQuestionSerial')
    updateMatchingFilter () {
        this.emitNextQuestion()
        this.selectMatchingFilter()
    }

    @Watch('currentFilter')
    nextQuestion () {
        this.emitNextQuestion()
    }

    @Emit('nextQuestion')
    emitNextQuestion () {
        return this.nextQuestionInFilter
    }

    @Emit('questionClicked')
    questionClicked (id?: string) {
        return id
    }

}

</script>

<style lang="scss" scoped>
.question-sidebar {
    width: 280px;
    height: 100%;
    position: fixed;
    left: 0;
    padding-top: 52px;
    padding-bottom: 57px;
    background-color: $evening;
    z-index: 99;
    color: $cloudy;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    :deep(thead) {
        position: sticky;
        top: 0;
        background-color: $moonlit-ocean;
        z-index: 2;
    }

    :deep(.uikit-table__cell) {
        overflow: visible;
        white-space: normal;
    }

    &__create-section {
        position: sticky;
        top: 0;
    }

    &__active-item {
        position: relative;
        display: inline-block;
        z-index: 1;
    }

    &__active-item-count {
        position: relative;
        display: inline-block;
        z-index: 1;
    }

    &__active-item-highlight {
        display: inline-block;
        position: absolute;
        border-radius: 4px;
        height: 16px;
        width: 12px;
        background-color: rgba($banana-bread, 0.4);
        top: -1px;
        right: -2px;
        z-index: -1;
    }

    &__head {
        background-color: $moonlit-ocean;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 8px 10px 12px;
    }

    &__head-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $white;
    }

    &__head-toggle-button {
        color: $banana-bread;
        padding: 2px 6px 1px 6px;
        margin-right: -6px;
        font-size: 14px;

        &:hover {
            background-color: rgba($banana-bread, 0.15);
        }
    }

    :deep(.uikit-table__subrow) {
        
        td:first-child {
            font-size: 12px;
        }
    }

    :deep(.uikit-table__row:last-of-type > .uikit-table__cell:first-of-type) {
        width: 190px!important;
    }

    &__prefix-number {
        position: absolute;
        left: 12px;
        top: 8px;
        text-align: right;
        width: 16px;
        font-weight: 600;
        color: $pewter;
    }

    &__subrow-toggle-cell {
        width: fit-content;
    }

    &__arrow-icon {
        margin-left: 1px;
        margin-bottom: 1px;
        height: 5px;
        width: 8px;
        
        &--expanded {
            transform: rotate(180deg);
        }
    }

    &__preview-section {
        overflow: auto;
    }

    &__questions-preview-section {
        overflow: auto;
        margin-bottom: 0;
        padding-bottom: 100px;
    }

    &__questions-preview-header {
        position: sticky;
        top: 0;
        background-color: $evening;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 8px 10px 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $white;
    }

    &__question-preview {
        padding: 12px 8px 11px 12px;
        border-bottom: 1px solid $pickled-bluewood;

        &:hover {
            cursor: pointer;
        }

        &--selected {
            background-color: $ash;
            color: $white;
            border-radius: 6px;
        }
        
        &--hover-enabled {

            &:hover {
                background-color: $brand-black;
            }
        }
    }

    &__question-preview-subject {
        font-size: 13px;
        line-height: 18px;
        color: $cloudy;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;

        &--selected {
            color: $fog;
        }
    }

    &__preview-icon {
        color: $butterscotch;
    }

    &__flag-icon {
        color: $butterscotch;
    }

    &__preview-tag-draft {
        background-color: $buttermilk;
        line-height: 16px;
        height: 16px;
        border: 1px solid $banana-bread;
        border-radius: 3px;
        font-size: 12px;
        padding: 0 3px;
        box-sizing: content-box;
        color: $brand-black;
        min-width: 85px;
    }

    &__preview-tag-unsubmitted {
        height: 16px;
        line-height: 16px;
        background-color: $fog;
        border-radius: 3px;
        font-size: 12px;
        padding: 0 3px;
        box-sizing: content-box;
        color: $brand-black;
        border: 1px solid rgba($pewter, 0.85);
    }

    &__question-preview-prompt {
        color: rgba($white, 0.7);
        line-height: 19px;
        font-size: 14px;
        overflow-wrap: break-word;
    }

    &__questions-preview-filter-dropdown {
        top: 50px;
        right: 10px;
    }

    &__empty-state {
        padding: 0 30px;

        :deep(.uikit-empty-state__row) {
            z-index: -1;
        }

        :deep(.uikit-empty-state__title) {
            color: $fog;
        }

        :deep(.uikit-empty-state__message) {
            padding: 0;
            color: $cloudy;
        }

        svg {
            z-index: -1;
        }
    }
}
</style>